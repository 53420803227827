export const Paises = [
    {

      nombre: "Mexico",
      tamanos: [
        "16x16x16",
        "18x18x18",
        "20x20x20",
        "18x18x28",
        "22x22x22",
        "24x24x24",
        "36x22x22",
        "36x24x24",
        "35x30x24",
      ],
      tiempoEntrega: "3 - 4 semanas",
      CobroPeso: "No se cobra por Peso",
    },
    {
      nombre: "Guatemala",
      tamanos: [
        "22x22x22",
        "24x24x24",
        "36x22x22",
        "36x24x24",
        "35x30x24",
        "30x30x30",
        "42x29x25",
      ],
      tiempoEntrega: "3 - 4 semanas",
      CobroPeso: "No se cobra por Peso",
    },
    {

      nombre: "El Salvador",
      tamanos: [
        "22x22x22",
        "24x24x24",
        "36x22x22",
        "36x24x24",
        "35x30x24",
        "30x30x30",
        "42x29x25",
      ],
      tiempoEntrega: "3 - 4 semanas",
      CobroPeso: "No se cobra por Peso",
    },
    {
      nombre: "Honduras",
      tamanos: [
        "22x22x22",
        "24x24x24",
        "36x22x22",
        "36x24x24",
        "35x30x24",
        "30x30x30",
        "42x29x25",
      ],
      tiempoEntrega: "3 - 4 semanas",
      CobroPeso: "No se cobra por Peso",
    },
    {
      nombre: "Nicaragua",
      tamanos: [
        "22x22x22",
        "24x24x24",
        "36x22x22",
        "36x24x24",
        "35x30x24",
        "30x30x30",
        "42x29x25",
      ],
      tiempoEntrega: "3 - 4 semanas",
      CobroPeso: "No se cobra por Peso",
    },
    {
      nombre: "Costa Rica",
      tamanos: [
        "22x22x22",
        "24x24x24",
        "36x22x22",
        "36x24x24",
        "35x30x24",
        "30x30x30",
        "42x29x25",
      ],
      tiempoEntrega: "3 - 4 semanas",
      CobroPeso: "No se cobra por Peso",
    },
    {
      nombre: "Colombia",
      tamanos: [
        "16x16x16",
        "18x18x18",
        "20x20x20",
        "18x18x28",
        "22x22x22",
        "24x24x24",
        "36x22x22",
        "36x24x24",
        "35x30x24",
        "30x30x30",
        "42x29x25",
      ],
      tiempoEntrega: "3 - 4 semanas",
      CobroPeso: "No se cobra por Peso",
    },
    {
    
      nombre: "Republica Dominicana",
      tamanos: ["18x18x18", "22x22x22", "24x24x24", "36x22x22"],
      tiempoEntrega: "3 - 4 semanas",
      CobroPeso: "No se cobra por Peso",
    },
  ];